import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange (DEX)',
    icon: 'TradeIcon',
    href: 'https://pancakeswap.finance/swap?outputCurrency=0xC40F70aF23457235CbEEA62dB0DE85931e125416',
  },
  {
    label: 'Add Liquidity',
    icon: 'LiquidityIcon',
    items: [
      {
        label: 'YERBAMATE/BNB on PancakeSwap',
        href: 'https://pancakeswap.finance/add/BNB/0xC40F70aF23457235CbEEA62dB0DE85931e125416',
      },
      {
        label: 'YERBAMATE/BUSD on PancakeSwap',
        href: 'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xC40F70aF23457235CbEEA62dB0DE85931e125416',
      },
    ],
  },
  {
    label: 'Yield Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Staking Pools',
    icon: 'PoolIcon',
    href: '/staking',
  },
 
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'PancakeSwap',
        href: 'https://pancakeswap.info/token/0xC40F70aF23457235CbEEA62dB0DE85931e125416',
      },
      {
        label: 'Coin Hunters',
        href: 'https://coinhunters.cc/tokens/YerbaMate',
      },
      {
        label: 'Dapp.com',
        href: 'https://www.dapp.com/app/yerba-mate-finance',
      },
      {
        label: 'Dappraddar.com',
        href: 'https://dappradar.com/binance-smart-chain/defi/yerba-mate-finance',
      },
      /* {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/',
      },
      {
        label: 'AstroTools',
        href: 'https://app.astrotools.io/pancake-pair-explorer/0',
      }, */
    ],
  }, 
  {
    label: 'Security',
    icon: 'AuditIcon',
    items: [
      {
        label: 'How to Verify Timelock',
        href: 'https://wiki.rugdoc.io/docs/how-to-verify-timelock/',
      },
      {
        label: 'How to Emergency Withdraw',
        href: 'https://wiki.rugdoc.io/docs/how-to-emergency-withdraw-bsc/',
      },
      {
        label: 'Emergency Withdraw Tool',
        href: 'https://rugdoc.io/emergency/',
      },
    ],
  },
  {
    label: 'Learn',
    icon: 'DocsIcon',
    items: [
      {
        label: 'Introduction to Binance Smart Chain (BSC)',
        href: 'https://wiki.rugdoc.io/docs/intro-to-bsc-binance-smart-chain/',
      },
      {
        label: 'What is DeFi and Why Is It The Future?',
        href: 'https://rugdoc.io/education/what-is-defi/',
      },
      {
        label: 'DeFi Farm 101: an introduction to yield farming',
        href: 'https://wiki.rugdoc.io/docs/defi-farm-101/',
      },
      {
        label: 'Introduction to Yield Farming',
        href: 'https://wiki.rugdoc.io/docs/yield-farming/',
      },
      {
        label: 'Introduction to liquidity pools',
        href: 'https://wiki.rugdoc.io/docs/introduction-to-liquidity-pools/',
      },
      {
        label: 'School of Blocks',
        href: 'https://youtube.com/playlist?list=PL6VM0N695IhnurbIjBTwzQ3cSeXvBJeJj',
      },
      {
        label: 'Rug Doc Wiki',
        href: 'https://wiki.rugdoc.io/',
      },


    ],
  },
  {
    label: 'Tutorials',
    icon: 'ListingsIcon',
    items: [
      {
        label: 'How to connect MetaMask to BSC',
        href: 'https://wiki.rugdoc.io/docs/how-to-connect-metamask-to-the-binance-smart-chain-network-bsc/',
      },
      {
        label: 'How to add a token to MetaMask',
        href: 'https://wiki.rugdoc.io/docs/how-to-add-a-token-to-metamask/',
      },
      {
        label: 'What is slippage?',
        href: 'https://wiki.rugdoc.io/docs/understanding-slippage/',
      },
      {
        label: 'Timelocks',
        href: 'https://wiki.rugdoc.io/docs/timelocks-explained/',
      },
      {
        label: 'Impermanent Loss Calc',
        href: 'https://dailydefi.org/tools/impermanent-loss-calculator/',
      },
    ],
  },


  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Blog',
        href: 'https://yerbamatefinance.medium.com/',
      },
      {
        label: 'Docs',
        href: 'https://docs.yerbamate.finance',
      },
      {
        label: 'Feedback',
        href: 'https://',
      },
      {
        label: 'GitHub',
        href: 'https://github.com/yerbamatefinance/',
      },
    ],
  },

  /* {
    label: 'Audit by Hacken',
    icon: 'AuditIcon',
    href: 'https://yerbamate.finance/files/',
  },  */
]

export default config