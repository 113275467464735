import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light as lightBase, dark as darkBase, PancakeTheme, lightColors } from '@pancakeswap-libs/uikit'


const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null })

const ThemeContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }

  // customize light theme
  const light: PancakeTheme = lightBase
  light.colors.text = '#191326'

  light.colors.primary = '#026838'
  light.colors.secondary = '#026838'
  light.colors.textSubtle = '#026838'

 
  light.colors.background = '#faf9fa'
  light.nav.background = '#FFF'
  light.card.background = '#FFF'
  

  
  light.colors.success = '#919f6b'



  light.button.primary.background = '#026838'
  light.button.primary.backgroundHover = '#849837'

  light.shadows.active = '#849837'

  light.button.subtle.background = '#849837'
  light.button.subtle.backgroundHover  = '#849837'

  light.colors.success = '#849837'

  light.radii.circle = '#849837'
  light.shadows.focus = '#026838'


  // customize dark theme
  const dark: PancakeTheme = darkBase
  dark.colors.text = '#eae2fc'

  dark.colors.primary = '#849837'
  dark.colors.secondary = '#849837'
  dark.colors.textSubtle = '#dbd9d9'
 
  dark.colors.background = 'rgba(0, 0, 0, 1)'
  dark.nav.background = 'rgba(0, 0, 0, 1)'
  dark.card.background = 'rgba(0, 0, 0, 0.7)'
  
  dark.colors.primaryDark = '#849837'
  
  dark.button.primary.background = '#849837'
  dark.button.primary.backgroundHover = '#026838'
  dark.button.subtle.background = '#849837'
  dark.button.subtle.backgroundHover  = '#026838'

  dark.colors.success = '#919f6b'

  dark.radii.circle = '#849837'
  dark.shadows.focus = '#026838'

  dark.colors.tertiary = '#026838'


  
 
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? lightBase : darkBase }>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
