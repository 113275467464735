export default {
  cake: {
    56: '0xC40F70aF23457235CbEEA62dB0DE85931e125416',
    97: '',
  },
  masterChef: {
    56: '0x8563BFF62dB658BB626712E466Fb0595A54FE2b1',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
