import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'YERBAMATE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x451FEEF1ae72B309436c10B441dcD934447109C6',
    },
    tokenSymbol: 'YERBAMATE',
    tokenAddresses: {
      97: '',
      56: '0xC40F70aF23457235CbEEA62dB0DE85931e125416',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'YERBAMATE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbbB2Bc9A7Aaf25a40d6B50ba7E95644100958539',
    },
    tokenSymbol: 'YERBAMATE',
    tokenAddresses: {
      97: '',
      56: '0xC40F70aF23457235CbEEA62dB0DE85931e125416',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'YERBAMATE',
    lpAddresses: {
      97: '',
      56: '0x451FEEF1ae72B309436c10B441dcD934447109C6', // YERBAMATE-BUSD LP
    },
    tokenSymbol: 'YERBAMATE',
    tokenAddresses: {
      97: '',
      56: '0xC40F70aF23457235CbEEA62dB0DE85931e125416',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  
  

]

export default farms